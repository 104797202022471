import {StudioApiBase} from '@jetCommon/api/studio/base.js';

export default class StudioApiCommon extends StudioApiBase {
    static resourceName = 'studios';

    uploadAvatar(studioOperatorId, data) {
        return this.apiPostForm(`${studioOperatorId}/avatar/`, data);
    }

    removeAvatar(studioOperatorId) {
        return this.apiDelete(`${studioOperatorId}/avatar/`);
    }
}
