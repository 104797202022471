import {ApiBase} from '@jetCommon/api/base.js';

export default class WelfareFundEmployeeMonthlyContributionApi extends ApiBase {
    static resourceName = 'welfare_fund_employee_monthly_contributions';

    getAggregated(params) {
        return this.apiGet('aggregated', {params});
    }

    getXlsxUrl(params) {
        return this.getApiUrlWithParams('download_xlsx/', params);
    }
}
